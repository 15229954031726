import type { I18nLocale } from "@/modules/i18n/types"
import type { PageTranslations } from "@/modules/layouts/contexts/foLayout"
import type { FunctionComponent } from "react"

import { clsx } from "clsx"
import { usePathname } from "next/navigation"

import { hasCustomTheme } from "@/modules/careerCenter/hasCustomTheme"
import { useCareerCenterFetch } from "@/modules/careerCenter/hooks/useCareerCenterFetch"
import { FLAG_ASSET } from "@/modules/headerFooterFO/flags/assets"
import { Icon } from "@/modules/headerFooterFO/Header/components/Icon/Icon"
import { getLocalesPathnames } from "@/modules/headerFooterFO/utils/getLocalesPathnames"
import { useTranslation } from "@/modules/i18n/components/useTranslation"
import { AVAILABLE_LOCALES } from "@/modules/i18n/constants"
import { PUBLIC_LOCALES } from "@/modules/locales/constants"
import { useLocale } from "@/modules/locales/useLocale"
import { Link } from "@/modules/routing/components/Link"
import { isHome } from "@/modules/routing/helpers/isPage"
import { useCurrentUserFetch } from "@/modules/user/client/hooks/useCurrentUserFetch"

import styles from "./LocalesDropdown.module.css"

export type LocalesDropdownProps = {
  from: "footer" | "header"
  pageTranslations?: PageTranslations
}

export const LocalesDropdown: FunctionComponent<LocalesDropdownProps> = ({ from, pageTranslations }) => {
  const { t } = useTranslation(["module_header_fo", "shared_languages"])
  const locale = useLocale()
  const pathname = usePathname()
  const { dataCurrentUser } = useCurrentUserFetch()
  const { dataCareerCenter } = useCareerCenterFetch()

  const hasCustomSchoolTheme = hasCustomTheme(dataCareerCenter?.careerCenterConf.theme)
  let localesToDisplay: I18nLocale[]
  let localesPathnames: PageTranslations | undefined = pageTranslations

  if (!localesPathnames) {
    if (hasCustomSchoolTheme && !!dataCurrentUser?.isLogged && dataCurrentUser?.attributes.locales) {
      localesToDisplay = dataCurrentUser.attributes.locales
    } else if (hasCustomSchoolTheme) {
      localesToDisplay = dataCareerCenter?.careerCenterConf.locales || []
    } else if (isHome(pathname)) {
      localesToDisplay = AVAILABLE_LOCALES
    } else {
      localesToDisplay = PUBLIC_LOCALES
    }

    localesPathnames = getLocalesPathnames({
      locale,
      locales: localesToDisplay,
      pathname,
    })
  }

  return (
    <div className={styles["hdr-DropdownMenu"]}>
      <div
        className={clsx(styles["hdr-DropdownMenu-item"], styles["hdr-DropdownMenu-item--flag"], {
          [styles["hdr-DropdownMenu-item--flagOnTop"]]: from === "header",
          [styles["hdr-DropdownMenu-item--flagOnBottom"]]: from === "footer",
        })}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0} // useful for Safari
      >
        <button
          type="button"
          className={clsx(styles["hdr-DropdownMenu-link"], {
            [styles["hdr-DropdownMenu-link--flagOnTop"]]: from === "header",
            [styles["hdr-DropdownMenu-link--flagOnBottom"]]: from === "footer",
          })}
          data-icon="expandMore|right"
        >
          <span className={styles["hdr-DropdownMenu-linkIcon"]}>
            <img src={FLAG_ASSET[locale]} width="18" height="18" style={{ display: "block" }} alt="" />
          </span>
          <span className={styles["hdr-DropdownMenu-linkLabel"]}>
            <span className="sr-only">{t("module_header_fo.selected_language")}</span>
            {t(`shared_languages.${locale}`)}
          </span>
          <Icon name="ArrowDown" className={styles["hdr-DropdownMenu-arrowIcon"]} />
        </button>
        <ul className={`js-DropdownMenu-submenu ${styles["hdr-DropdownMenu-submenu"]}`}>
          {Object.entries(localesPathnames).map(([localeToDisplay, href]) => (
            <li
              data-dropdown-locale={localeToDisplay}
              className={clsx(styles["hdr-DropdownMenu-submenuItem"], {
                [styles["hdr-DropdownMenu-submenuItem--selected"]]: localeToDisplay === locale,
              })}
              key={localeToDisplay}
            >
              <Link
                {...(localeToDisplay === locale ? { "aria-current": "page" } : {})}
                href={href}
                className={styles["hdr-DropdownMenu-submenuLink"]}
              >
                <figure className={styles["hdr-DropdownMenu-submenuLinkItem"]}>
                  <img
                    src={FLAG_ASSET[localeToDisplay as I18nLocale]}
                    width="18"
                    height="18"
                    style={{ display: "block" }}
                    alt=""
                  />
                  <figcaption className={styles["hdr-DropdownMenu-submenuLinkText"]}>
                    {t(`shared_languages.${localeToDisplay}`)}
                  </figcaption>
                </figure>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
